declare let cordova: Cordova;

import {
  StorageInterface,
  Oauth2Authentication,
  Oauth2AuthenticationOptions,
  Oauth2AuthTokens,
} from "@savagebull/common";
import LocalStorage from "./LocalStorage";
import { Capacitor } from "@capacitor/core";
import Configuration from "@/lib/Configuration";

const oauth = Configuration.oauth2;

const options: Oauth2AuthenticationOptions = {
  clientId: oauth.clientId || "",
  authorizeURL: oauth.authUrl || "",
  accessTokenURL: oauth.accessTokenUrl || "",
  refreshTokenURL: oauth.refreshTokenUrl || "",
  redirectURL: oauth.redirectUrl || "",
  scopes: ["marker"],
};

/*
 * a module scoped constant allowing for the processing of the cordova messageHandler
 */
let AuthWindowMessageHandler:
  | null
  | ((
      message: InAppBrowserEventListenerOrEventListenerObject | Event
    ) => void) = null;

if (Capacitor.getPlatform() !== "web") {
  // use the cordova in app frame
  options.customOauth2Process = (url: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const authWindow = cordova.InAppBrowser.open(url, "_blank");
      if (authWindow === null) {
        reject("Window is not a window");
        return;
      }

      // If there was a message handler set before that means
      // we need to remove the current "message" event handler
      // so we can re add it.
      if (AuthWindowMessageHandler !== null) {
        authWindow.removeEventListener("message", AuthWindowMessageHandler);
      }
      AuthWindowMessageHandler = (
        message: InAppBrowserEventListenerOrEventListenerObject | Event
      ): void => {
        /* eslint-disable */
          if (
              typeof message === "object" &&
              message !== null &&
              Object.prototype.hasOwnProperty.call(message, "type") &&
              // @ts-ignore
              message.type === "message" &&
              Object.prototype.hasOwnProperty.call(message, "data") &&
              // @ts-ignore
              Object.prototype.hasOwnProperty.call(message.data, "code")
          ) {
            // @ts-ignore
            const data = message.data;
            /* eslint-enable */
          if (data !== null) {
            resolve(data.code);
            authWindow.close();
          }
        }
      };

      authWindow.addEventListener("message", AuthWindowMessageHandler);
      authWindow.addEventListener("exit", () => {
        if (AuthWindowMessageHandler !== null) {
          authWindow.removeEventListener("message", AuthWindowMessageHandler);
          AuthWindowMessageHandler = null;
        }
      });
    });
  };
}

export class Authentication {
  private readonly authModule: Oauth2Authentication;
  private readonly cache: StorageInterface;

  constructor() {
    this.cache = LocalStorage;
    this.authModule = new Oauth2Authentication(options, this.cache);
  }

  init(doRefresh: boolean): Promise<boolean> {
    return this.authModule.init(doRefresh);
  }

  requestToken(authCode: string): Promise<Oauth2AuthTokens> {
    return this.authModule.requestToken(authCode);
  }

  getToken(): string | null {
    return this.authModule.getToken();
  }

  refreshToken(): Promise<string> {
    return this.authModule.refreshToken();
  }

  login(): Promise<Oauth2AuthTokens | string | null> {
    return this.authModule.login();
  }

  processOauthLoginReturnTokenOnly(): Promise<
    Oauth2AuthTokens | string | null
  > {
    return this.authModule.login(false);
  }

  setAuthTokens(tokens: Oauth2AuthTokens): Promise<void> {
    return this.authModule.persistToken(tokens);
  }

  logout(): Promise<void> {
    return this.authModule.logout();
  }

  onStateChange(fn: (evt: string, data: unknown) => void): void {
    this.authModule.onStateChange(fn);
  }

  public decodeToken(accessToken: string): { [key: string]: unknown } {
    return this.authModule.decodeJWT(accessToken);
  }
}

export default new Authentication();

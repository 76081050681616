import Vue from "vue";
import Vuex from "vuex";
import { default as Auth, AuthenticationState } from "./authentication";
import { default as Components, ComponentState } from "./components";
import { default as App, AppState, AppSettings } from "./app";
import { default as Snackbar, SnackbarState } from "@/lib/store/snackbar";
import LocalStorage from "@/lib/LocalStorage";

Vue.use(Vuex);

export type StoreState = {
  auth: AuthenticationState;
  components: ComponentState;
  app: AppState;
  snackbar: SnackbarState;
};

export default new Vuex.Store({
  modules: {
    auth: Auth,
    components: Components,
    app: App,
    snackbar: Snackbar,
  },
  mutations: {
    /**
     * Preload the ScoredMatches from the local store if they exist
     * @param state {StoreState}
     */
    loadStore(state: StoreState) {
      if (LocalStorage.has("settings")) {
        state.app.settings = LocalStorage.get("settings") as AppSettings;
      }
    },
  },
});

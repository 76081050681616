/**
 * Authentication storage for Vue
 */
import MatchExtModel from "@/Classes/MatchExtModel";

export type AppState = {
  scoredMatches: MatchExtModel[];
  settings: AppSettings;
};
export type AppSettings = {
  isBetaPlatform: boolean;
};
export default {
  namespaced: true,
  state: {
    scoredMatches: [],
    settings: {
      isBetaPlatform: false,
    },
  } as AppState,
  getters: {
    scoredMatches(state: AppState) {
      return state.scoredMatches;
    },
    settings(state: AppState): AppSettings {
      return state.settings;
    },
  },
  actions: {},
  mutations: {
    setSettings(state: AppState, settings: AppSettings) {
      state.settings.isBetaPlatform = settings.isBetaPlatform;
    },
    addScoredMatch: (state: AppState, match: MatchExtModel) => {
      const index = state.scoredMatches.findIndex(
        (scoredMatch) => scoredMatch.id === match.id
      );

      // Only add if the match has not been stored.
      if (index === -1) {
        state.scoredMatches.push(match);
      }
    },
    removeScoredMatch: (state: AppState, match: MatchExtModel) => {
      const idx = state.scoredMatches.indexOf(match);
      state.scoredMatches.splice(idx, 1);
    },
    updateScoredMatch: (state: AppState, match: MatchExtModel) => {
      const index = state.scoredMatches.findIndex(
        (scoredMatch) => scoredMatch.id === match.id
      );

      if (index !== -1) {
        state.scoredMatches[index] = match;
      }
    },
  },
};

/**
 * Authentication storage for Vue
 */
export type ComponentState = {
  navShown: boolean;
};

export default {
  namespaced: true,
  state: {
    navShown: false,
  },
  getters: {
    isNavVisible(state: ComponentState) {
      return state.navShown;
    },
  },
  actions: {},
  mutations: {
    toggleNav: (state: ComponentState, newState: boolean | null = null) => {
      if (newState === null) {
        state.navShown = !state.navShown;
      } else {
        state.navShown = newState;
      }
    },
  },
};

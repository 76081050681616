import { Config } from "@savagebull/common";

type oAuth2Config = {
  clientId: string | null;
  authUrl: string | null;
  accessTokenUrl: string | null;
  refreshTokenUrl: string | null;
  redirectUrl: string | null;
};

export class ConfigClass extends Config {
  /**
   * Returns the base url for the API.
   *
   * @return {string}
   */
  public get apiBaseUrl(): string {
    return <string>super.get("API_BASE_URL", "");
  }

  /**
   * Get the oAUTH2 Configuration object
   */
  public get oauth2(): oAuth2Config {
    return {
      clientId: super.get("OAUTH_CLIENT_ID", null) as string | null,
      authUrl: super.get("OAUTH_AUTHORIZATION_URL", null) as string | null,
      accessTokenUrl: super.get("OAUTH_ACCESS_TOKEN_URL", null) as
        | string
        | null,
      refreshTokenUrl: super.get("OAUTH_REFRESH_TOKEN_URL", null) as
        | string
        | null,
      redirectUrl: super.get("OAUTH_REDIRECT_URL", null) as string | null,
    };
  }
}

const config = new ConfigClass();
config.initEnv();

export default config;

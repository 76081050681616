/**
 * Authentication storage for Vue
 */
export type AuthenticationState = {
  loggedIn: boolean;
  firstName: string;
  lastName: string;
  key: string;
  uuid: string;
};

export default {
  namespaced: true,
  state: {
    loggedIn: false,
    firstName: "",
    lastName: "",
    key: "",
    uuid: "",
  },
  getters: {
    isLoggedIn: (state: AuthenticationState) => {
      return state.loggedIn;
    },
    name: (state: AuthenticationState) => {
      return state.firstName + " " + state.lastName;
    },
    id: (state: AuthenticationState) => {
      return state.uuid;
    },
    nin: (state: AuthenticationState) => {
      const parts = state.key.split(":");
      return parts[0];
    },
  },
  actions: {},
  mutations: {
    login: (
      state: AuthenticationState,
      userData: {
        accountId: string;
        firstName: string;
        lastName: string;
        username: string;
      }
    ) => {
      if (
        typeof userData == "object" &&
        userData != null &&
        Object.prototype.hasOwnProperty.call(userData, "accountId") &&
        Object.prototype.hasOwnProperty.call(userData, "username") &&
        Object.prototype.hasOwnProperty.call(userData, "firstName") &&
        Object.prototype.hasOwnProperty.call(userData, "lastName")
      ) {
        state.loggedIn = true;
        state.uuid = userData.accountId;
        state.firstName = userData.firstName;
        state.lastName = userData.lastName;
        state.key = userData.username;
      }
    },
    logout: (state: AuthenticationState) => {
      state.loggedIn = false;
      state.firstName = "";
      state.lastName = "";
      state.uuid = "";
      state.key = "";
    },
  },
};

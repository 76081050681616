import Vue from "vue";
import LocalStorage from "@/lib/LocalStorage";
import { MatchModel } from "@memberpoint/ba-result-components";
import cloneDeep from "lodash/cloneDeep";

export default new Vue({
  data() {
    return {
      matches: [] as MatchModel[],
    };
  },
  methods: {
    /**
     * Triggered when the store is updated
     */
    changed() {
      this.save();
      this.$emit("change");
    },

    /**
     * Export saved matched
     */
    save(): string {
      const packed = JSON.stringify(this.matches);
      LocalStorage.set("matches", packed);
      return packed;
    },

    /**
     * Get the collection of matches
     */
    getMatches(): MatchModel[] {
      return cloneDeep(this.matches);
    },

    /**
     * Load saved matches from local storage
     */
    init(): void {
      if (LocalStorage.has("matches")) {
        const scoredMatches = JSON.parse(
          LocalStorage.get("matches") as string
        ) as Array<unknown>;
        this.matches = [];

        // Loop through every scored match and store any valid matches
        // with hydrated data.
        for (const i in scoredMatches) {
          if (
            Object.prototype.hasOwnProperty.call(scoredMatches, i) &&
            scoredMatches[i] &&
            typeof scoredMatches[i] === "object"
          ) {
            try {
              const scoredMatch = MatchModel.hydrate(
                scoredMatches[i] as { [key: string]: unknown }
              );

              if (scoredMatch.id) {
                this.matches.push(scoredMatch);
              }
            } catch {
              // Unable to hydrate match
            }
          }
        }
        this.changed();
      }
    },

    /**
     * If there are saved matches
     */
    hasMatches(): boolean {
      return this.matches.length > 0;
    },

    /**
     * Check to see if a match is saved
     * @param matchId
     */
    hasMatch(matchId: string | MatchModel): boolean {
      if (matchId instanceof MatchModel) {
        matchId = matchId.id;
      }
      const index = this.matches.findIndex(
        (scoredMatch) => scoredMatch.id === matchId
      );
      return index !== -1;
    },

    /**
     * Add a match to the listing
     * @param match
     */
    addMatch(match: MatchModel): boolean {
      if (!this.hasMatch(match)) {
        this.matches.push(match);
        this.changed();
        return true;
      }
      return false;
    },

    /**
     * Update match
     */
    updateMatch(match: MatchModel): void {
      const index = this.matches.findIndex(
        (scoredMatch) => scoredMatch.id === match.id
      );
      if (index !== -1) {
        this.matches.splice(index, 1, match);
        this.changed();
      }
    },

    /**
     * remove a match
     */
    removeMatch(match: MatchModel | string): void {
      if (match instanceof MatchModel) {
        match = match.id;
      }
      const index = this.matches.findIndex(
        (scoredMatch) => scoredMatch.id === match
      );
      if (index !== -1) {
        this.matches.splice(index, 1);
        this.changed();
      }
    },
  },
});

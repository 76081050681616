import { PluginObject } from "vue";
import { Store } from "vuex";
import { SnackbarArg } from "@/lib/store/snackbar";
import { StoreState } from "@/lib/store";

export interface SnackbarPluginOptions {
  store: Store<StoreState>;
}

export interface SnackbarPlugin {
  show: (arg: SnackbarArg) => void;
  success: (arg: SnackbarArg) => void;
  error: (arg: SnackbarArg) => void;
  info: (arg: SnackbarArg) => void;
  hide: (index: number) => void;
  clearAll: () => void;
}

const plugin: PluginObject<SnackbarPluginOptions> = {
  install: (Vue, options?: SnackbarPluginOptions) => {
    if (!options || !options.store) {
      throw new Error("Vuex store required.");
    }

    Vue.prototype.$snackbar = {
      show(arg: SnackbarArg) {
        options.store.commit("snackbar/show", arg, { root: true });
      },
      success(arg: SnackbarArg) {
        options.store.commit("snackbar/success", arg, { root: true });
      },
      error(arg: SnackbarArg) {
        options.store.commit("snackbar/error", arg, { root: true });
      },
      info(arg: SnackbarArg) {
        options.store.commit("snackbar/info", arg, { root: true });
      },
      hide(index: number) {
        options.store.commit("snackbar/hide", index, { root: true });
      },
      clearAll() {
        options.store.commit("snackbar/clearAll", null, { root: true });
      },
    } as SnackbarPlugin;
  },
};

export default plugin;

export interface SnackbarProps {
  content: string;
  show?: boolean;
  transition?: string | boolean;
  top?: boolean;
  bottom?: boolean;
  centered?: boolean;
  left?: boolean;
  right?: boolean;
  timeout?: number | string;
  color?: string;
  contentClass?: string;
  elevation?: number | string;
  height?: number | string;
  width?: number | string;
  closeButtonColor?: string;
  html?: boolean;
}

export type SnackbarArg = SnackbarProps | string;

export interface SnackbarState {
  snackbars: SnackbarProps[];
}

/**
 * Resolve the arguments for adding snackbar.
 *
 * @param {SnackbarArg} snackbarArg
 * @return {SnackbarProps}
 */
const resolveSnackbarPropsFromArg = (
  snackbarArg: SnackbarArg
): SnackbarProps => {
  let defaultProps: SnackbarProps = {
    content: "",
    top: true,
    transition: "slide-y-transition",
    timeout: 5000,
  };

  if (snackbarArg && typeof snackbarArg === "object") {
    defaultProps = { ...defaultProps, ...snackbarArg };
  } else {
    // arg is a string so just pass it to the content.
    defaultProps.content = snackbarArg;
  }

  if (defaultProps.bottom === true) {
    defaultProps.top = false;
  }

  return defaultProps;
};

/**
 * Add a snackbar to the state.
 *
 * @param {SnackbarState} state
 * @param {SnackbarArg} snackbarArg
 */
const addSnackbar = (state: SnackbarState, snackbarArg: SnackbarArg) => {
  const props = resolveSnackbarPropsFromArg(snackbarArg);

  props.show = false;

  state.snackbars.push(props);

  setTimeout(() => {
    // Allows for transition.
    props.show = true;
  }, 10);
};

export default {
  namespaced: true,
  state: {
    snackbars: [],
  } as SnackbarState,
  getters: {
    snackbars(state: SnackbarState): SnackbarProps[] {
      return state.snackbars;
    },
  },
  mutations: {
    /**
     * Show a snackbar.
     *
     * @param {SnackbarState} state
     * @param {SnackbarArg} snackbarArg
     */
    show(state: SnackbarState, snackbarArg: SnackbarArg) {
      addSnackbar(state, snackbarArg);
    },

    /**
     * Show a success snackbar.
     *
     * @param {SnackbarState} state
     * @param {SnackbarArg} snackbarArg
     */
    success(state: SnackbarState, snackbarArg: SnackbarArg) {
      const props = resolveSnackbarPropsFromArg(snackbarArg);

      props.color = "success";
      props.closeButtonColor = "success darken-3";

      addSnackbar(state, props);
    },

    /**
     * Shows a error snackbar.
     *
     * @param {SnackbarState} state
     * @param {SnackbarArg} snackbarArg
     */
    error(state: SnackbarState, snackbarArg: SnackbarArg) {
      const props = resolveSnackbarPropsFromArg(snackbarArg);

      props.color = "error";
      props.closeButtonColor = "error darken-3";

      addSnackbar(state, props);
    },

    /**
     * Shows a info snackbar.
     *
     * @param {SnackbarState} state
     * @param {SnackbarArg} snackbarArg
     */
    info(state: SnackbarState, snackbarArg: SnackbarArg) {
      const props = resolveSnackbarPropsFromArg(snackbarArg);

      props.color = "secondary";
      props.closeButtonColor = "primary";

      addSnackbar(state, props);
    },

    /**
     * Hide and removes a snackbar at the given index.
     *
     * @param {SnackbarState} state
     * @param {number} index
     */
    hide(state: SnackbarState, index: number) {
      if (state.snackbars[index]) {
        state.snackbars[index].show = false;
      }
    },

    /**
     * Clear all snackbars from the state.
     *
     * @param {SnackbarState} state
     */
    clearAll(state: SnackbarState) {
      state.snackbars.forEach((snackbar) => {
        snackbar.show = false;
      });

      setTimeout(() => {
        state.snackbars.splice(0);
      }, 100);
    },
  },
};
